.table-Active {
  background-color: #61ae61
}

.table-Active-OverMaxWatchers {
  background-color: #307b4a
}

.table-Bench {
  background-color: #e86850
}

.table-Bench-OverMaxWatchers {
  background-color: #b5474e
}

.table-Ace {
  background-color: #ffeb7a
}

.table-OverMaxWatchers {
  background-color: #f5f1e6
}

.table-Boost {
  background-color: #587498
}

.table {
  background-color: #fffefa
}